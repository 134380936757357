export default {
  data() {
    return {
      surface_mesurer: 0,
      surface: 0,
      nombre_bati: 0,
      surface_non_bati: null,
      donnee_imprecise: 'Donnée publique imprécise',
      surface_cadastrale_exist: true,
    }
  },

  methods: {
    SumSurfaceCadastrale(all_parcelles) {
      let surface = 0
      all_parcelles.forEach((parcelle) => {
        surface += this.getSurfaceCadastrale(parcelle)
      })
      return surface
    },
    getSurfaceCadastrale(parcelle) {
      let contenance = parcelle.properties.contenance
      let surface = contenance !== '' ? contenance : 0
      return surface
    },
    getIntersectParcelles(allParcelles, parcelle_selected) {
      var lineIntersect = null
      allParcelles.some((parcelle) => {
        lineIntersect = this.$turf.booleanOverlap(parcelle_selected, parcelle)
        return lineIntersect
      })
      return lineIntersect
    },
    getRefParcelle(parcelle) {
      return parcelle.properties.section + '' + parcelle.properties.numero
    },
    addRefCadastaleToPropertiesFeaturesAutour(features_autour) {
      features_autour.map(function (feature) {
        feature.parcelle.properties.id = feature.parcelle.id
      })
      return [...features_autour]
    },
    initParcellesVoisines(
      parcelles_voisines,
      color = this.blue,
      is_bati = false,
    ) {
      parcelles_voisines.forEach((parcelle) => {
        let reference_cadastrale_selected = !is_bati
          ? parcelle.properties.id
          : parcelle.id
        let options = this.copyObject(this.options_polygone)

        options.color = color
        this.addPolygonsToMap(
          parcelle,
          this.concatSourceAndLayersWithRef(
            ...this.init_source_and_layers_parcelles_voisines,
            reference_cadastrale_selected,
          ),
          options,
        )
      })
    },
    getParcellesAutour(features_autour) {
      return features_autour.map((feature) => {
        return feature.parcelle
      })
    },
    addParcellesAutour(parcelles_autour) {
      let options = this.copyObject(this.options_polygone)
      options.color = this.grey
      options.is_dash = false
      options.is_multi_geojson = true
      this.addPolygonsToMap(
        parcelles_autour,
        this.source_layers_selected_parcelles_autour,
        options,
      )
    },
    addLengthWellToMap(tabWall, moduleStore) {
      this.removeMarkers(this.markers_walls)
      this.$store.commit(`${moduleStore}/MARKERS_WALLS`, [])

      let current_markers = []
      tabWall.forEach((wall) => {
        // create a DOM element for the marker
        if (wall.sizeWall > 2) {
          var el = document.createElement('div')
          el.className = 'marker'
          el.style.backgroundColor = 'white'
          el.innerHTML = wall.sizeWall + ' m'
          // add marker to map
          let marker = new this.$mapboxgl.Marker(el)
            .setLngLat(wall.middleWall)
            .addTo(this.map)
          current_markers.push(marker)
        }
      })
      this.$store.commit(`${moduleStore}/MARKERS_WALLS`, current_markers)
    },
    resetParcelleVoisines(is_dash = false) {
      this.removeSourceAndLayers(...this.source_and_layers_parcelles_aggregate)
      this.informations.parcelles_voisines.forEach((parcelle) => {
        this.removeSourceAndLayers(
          ...this.concatSourceAndLayersWithRef(
            ...this.source_and_layers_parcelles_voisines,
            parcelle.properties.id,
          ),
        )
      })
      if (is_dash) {
        this.initParcellesVoisines(this.informations.parcelles_voisines)
      } else {
        this.informations.parcelles_voisines = []
      }
    },
    setInfosParcelles(parcelles) {
      let taille_murs = this.getTailleMurs(this.getUnionParcelles())
      this.addLengthWellToMap(taille_murs, 'parcelle')
      this.$emit('getInfosParcelles', {
        surface_parcelles: this.SumSurfaceCadastrale(parcelles),
        surface_batiments: this.totalSurfaceSolBatis(parcelles),
        taille_murs: taille_murs,
      })
    },
    getFeaturesExist(all_parcelles, features_autour) {
      let features_autour_exist = []
      let ids_parcelles = all_parcelles.map(
        (parcelle) => parcelle.properties.id,
      )

      features_autour.forEach(function (feature_autour) {
        if (ids_parcelles.includes(feature_autour.parcelle.properties.id)) {
          features_autour_exist.push(feature_autour)
        }
      })
      return features_autour_exist
    },
    totalSurfaceSolBatis(parcelles) {
      let surface = 0
      let all_parcelles = [
        this.informations.current_parcelle,
        ...this.informations.parcelles_voisines,
      ]
      let features_exist = this.getFeaturesExist(
        all_parcelles,
        this.features_autour,
      )
      let allBatiments = this.getAllBatimentsWithUniqueId(
        features_exist,
        parcelles,
      )
      this.informations.current_batiment = allBatiments

      allBatiments.forEach((batiment) => {
        surface += this.surfaceSolBati(batiment)
      })

      return this.round(surface)
    },
  },
}
